import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <br />
    <h5>{`At GameBuilders we make games and tools to help other developers.`}</h5>
    <p>{`Our story began in 2018 when a team of students partner up to create games using the Unity Engine.`}<br parentName="p"></br>{`
`}{`More precisely our idea was initially to create a solid foundation for the development of future games.`}<br parentName="p"></br>{`
`}{`Two years after the beginning of development we were so proud of our creation, named FPS Builder, that we thought
it would be a good idea if other teams could also benefit from this project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      